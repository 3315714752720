import { colors } from "@/constants";
import styled, { css } from "styled-components";
import { RiCloseLine } from "react-icons/ri";

interface Props {
  $isopen: boolean;
  $fullHeight?: boolean;
  direction?: "bottom" | "right";
  $bottomMargin?: number;
  $showOverflow?: boolean | null;
}

export const ModalContainer = styled.div`
  position: absolute;
`;

const modalEffects = (i: any) => {
  return css`
    ${i.direction === "bottom" &&
    css`
      bottom: ${i.$isopen ? "0px" : "-110vh"};
    `}
    ${i.direction === "right" &&
    css`
      bottom: 0px;
      left: ${i.$isopen ? "" : "155%"};
    `}
  `;
};

export const BgContainer = styled.div<Props>`
  left: 50%;
  width: 100%;
  height: 100vh;
  z-index: 9998;
  position: fixed;
  transition: all 0.4s;
  max-width: var(--mobile);
  transform: translateX(-50%);
  background-color: ${colors.shadown1};
  opacity: ${({ $isopen }) => ($isopen ? 1 : 0)};
  ${(i) => modalEffects(i)};
  `;

export const ModalContent = styled.div<Props>`
  z-index: 9999;
  position: fixed;
  opacity: ${({ $isopen }) => ($isopen ? 1 : 0)};
  overflow: ${(i) => (i.$showOverflow ? "visible" : "hidden")};
  left: calc(50% - 1px);
  width: calc(100% + 2px);
  transform: translateX(-50%);
  transition: all 0.35s ease-out;
  max-width: calc(var(--mobile) + 2px);
  background-color: ${colors.brand.white};
  box-shadow: 0px -3px 10px 0px ${colors.shadown3};
  max-height: ${(i) => (i.$fullHeight ? 100 : 75)}dvh;
  height: ${(i) => (i.$fullHeight ? "100dvh" : "auto")};
  border-top-left-radius: ${(i) => (i.$fullHeight ? 0 : 8)}px;
  border-top-right-radius: ${(i) => (i.$fullHeight ? 0 : 8)}px;
  ${(i) => modalEffects(i)};
  ${(props) =>
    props.$bottomMargin &&
    css`
      margin-bottom: ${props.$bottomMargin}px;
    `}
`;

export const ModalBody = styled.div<{ $fullHeight?: boolean }>`
  width: 100%;
  overflow-y: auto;
  max-height: ${(i) => (i.$fullHeight ? 100 : 75)}vh;
`;

export const Header = styled.div`
  width: 100%;
  z-index: 15;
  right: 0px;
  position: absolute;
  display: inline-flex;
  justify-content: end;
`;

export const CloseIcon = styled(RiCloseLine)`
  margin-top: 8px;
  margin-right: 8px;
  color: ${colors.gray.gray40};
`;

export const HeaderIcon = styled.div`
   width: 220px;
   height: 220px;
   position: absolute;
   top: -110px;
   left: 50%; 
   transform: translateX(-50%); 

`;

