import { colors } from "@/constants";
import styled, { css } from "styled-components";

export const Tolltip = styled.div<{
  $show: boolean;
  $bgcolor?: string;
  direction: "top" | "bottom";
}>`
  left: 50%;
  ${(i) =>
    i.direction === "bottom"
      ? css`
          bottom: 0px;
        `
      : css`
          top: 0px;
        `};
  display: flex;
  font-size: 12px;
  padding: 4px 8px;
  max-width: 150px;
  text-align: center;
  position: absolute;
  border-radius: 8px;
  width: max-content;
  transition: all 0.4s;
  color: ${colors.brand.white};
  background: ${(i) => i.$bgcolor ?? colors.grey7 + "f2"};
  z-index: ${(i) => (i.$show ? 1 : -1)};
  opacity: ${(i) => (i.$show ? 1 : 0)};
  ${(i) =>
    i.direction === "bottom"
      ? css`
          transform: translateY(25%) translateX(-50%);
        `
      : css`
          transform: translateY(-110%) translateX(-50%);
        `}

  &:before {
    content: "";
    ${(i) =>
      i.direction === "bottom"
        ? css`
            top: 0px;
          `
        : css`
            bottom: 0px;
          `};
    right: 50%;
    border-width: 5px;
    position: absolute;
    border-style: solid;
    ${(i) =>
      i.direction === "bottom"
        ? css`
            transform: translateX(50%) translateY(-100%);
          `
        : css`
            transform: translateX(50%) rotate(180deg) translateY(-100%);
          `}
    border-color: transparent transparent ${(i) =>
      i.$bgcolor ?? colors.grey7 + "f2"};
  }
`;

export const Content = styled.div`
  position: relative;
`;
