import styled from "styled-components";
import { colors } from "@/constants";

export const AddToCartWrapper = styled.div`
  width: 100%;
`;

export const Unavailabel = styled.button`
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0px;
  border-radius: 6px;
  border: none;
  width: 100%;
  text-align: center;
  color: ${colors.grey3};
  background-color: ${colors.gray.gray10};
`;

export const AddToCartButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: 45px;

  background-color: ${({disabled}) => disabled ? colors.grey8 : colors.brand.red};
  border: none;
  border-radius: 8px;

  font-size: 16px;
  line-height: 18.75px;
  color: ${colors.brand.white};

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const CounterWrapper = styled.div``;
