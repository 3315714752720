import * as S from "./styles";
import { getOnStorage } from '@/shared'
import { useUserContext } from "@/hooks";
import { useEffect, useMemo } from "react";
import * as R from "@/constants/systemRoutes";
import { useNavigate } from "react-router-dom";
import { TextInput, ModalSync } from "@/components";
import { useSignIn } from "@/talons/signInPage/useSignIn";
import { ResetPassword } from "@/components/reset-password/ResetPassword";

const SignIn = () => {
  const navigate = useNavigate();
  const { isSignedIn } = useUserContext();

  const {
    loading,
    emailValid,
    inputValue,
    inputError,
    loginError,
    taxVatExist,
    passwordValue,
    passwordValid,
    inputMaxValue,
    isLoadingFiles,
    isContinueDisabled,
    handleSignIn,
    setIsSignedIn,
    handleRedirect,
    handleLoginInput,
    setIsLoadingFiles,
    handlePasswordChange,
  } = useSignIn();

  useEffect(() => {
    if (isSignedIn) {
      const lastRoute = getOnStorage('last_route')
      try {
        // navigate(-1);
        navigate(lastRoute || R.DASHBOARD.link);
      } catch (err) {}
    }
  }, [isSignedIn, navigate])

  const continueDisabled = useMemo(() => {
    if (isContinueDisabled) {
      return isContinueDisabled;
    }

    return !inputValue || !passwordValue || !passwordValid || loading || isLoadingFiles;
  }, [inputValue, passwordValue, loading, isLoadingFiles, isContinueDisabled, passwordValid]);

  return (
    <S.SignInWrapper className="full-wrapper">
      <S.PageHeader>
        <S.CallToAction>Entre para comprar</S.CallToAction>
        <S.Message>Faça o login com seus dados abaixo: </S.Message>
        {loginError && <S.Error>{loginError.message}</S.Error>}
      </S.PageHeader>

      <S.Form onSubmit={handleSignIn}>
        <S.FormController>
          <TextInput
            value={inputValue}
            onChange={handleLoginInput}
            type="email"
            fillwidth
            id={"inputEmail"}
            name={"inputEmail"}
            label="Digite o seu E-mail"
            data-test="login-input-email"
            required={true}
            success={emailValid || taxVatExist}
            error={inputError?.error}
            errorMessage={inputError?.message}
            maxLenght={inputMaxValue}
          />
        </S.FormController>

        <S.FormController>
          <TextInput
            value={passwordValue}
            onChange={handlePasswordChange}
            type="password"
            fillwidth
            id={"inputPassword"}
            name={"inputPassword"}
            label="Digite sua senha"
            data-test="login-input-senha"
            required={true}
            success={passwordValid}
          />
        </S.FormController>

        <S.FormController>
          <S.SmallText>
            Não lembra sua senha?{" "}
            <S.TextAction
              onClick={handleRedirect}
              data-test="login-redefinir-senha"
            >
              Clique aqui...
            </S.TextAction>
          </S.SmallText>
        </S.FormController>

        <S.FormController>
          <S.ContinueButton
            type="submit"
            disabled={continueDisabled}
            data-test="login-submit-continuar"
            $loading={loading || isLoadingFiles}
          >
            Continuar
          </S.ContinueButton>
        </S.FormController>
      </S.Form>

      <ModalSync
        isLoading={isLoadingFiles}
        cbAction={() => {
          setIsLoadingFiles(false);
          navigate(R.DASHBOARD.link);
          setIsSignedIn(true);
        }}
      />

      <ResetPassword />
    </S.SignInWrapper>
  );
};

export default SignIn;
