import React, { useCallback, useState } from 'react';
import * as S from './styles';
import { ModalOrderStatus, OrderStatusCard, OrderStatusHeader as Header } from '@/components';
import { OrderItemsProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import { ClientProps, ProductProps } from '@/types';

interface CardProps {
  clientName: string;
  client: ClientProps;
  orders: OrderItemsProps[];
  clientRefNumber: string;
  clientCnpj: string;
  isLast?: boolean;
  date?: string;
  productsDict?: Record<number, ProductProps>;
}

const Card = ({ clientName, orders, clientRefNumber, date, clientCnpj, client }: CardProps) => {

  const [isOpen, setIsOpen] = useState('');

  const toggleModal = useCallback((status: string) => {
    setIsOpen(status);
  }, [])

  return (
    <S.CardContainer>
      <Header client={client} clientName={clientName} clientRefNumber={clientRefNumber} />
      <React.Fragment>
        {
          orders?.map((order, index) => {
            if (order?.orderDate === date && orders.length > 0) {
              return (
                <>
                  <OrderStatusCard
                    order={order}
                    key={`order-status-card-${index}`}
                    toggleModal={toggleModal}
                    isLast={index === orders?.length - 1}
                  />
                  <ModalOrderStatus
                    client={client}
                    key={`order-status-modal-${index}`}
                    clientCnpj={clientCnpj}
                    clientName={clientName}
                    clientRefNumber={clientRefNumber}
                    order={order}
                    isOpen={order?.orderNumber === isOpen}
                    closeModal={() => toggleModal('')}
                  />
                </>
              )
            }
            return null;
          })

        }
      </React.Fragment>
    </S.CardContainer>
  )
}

export default Card;
