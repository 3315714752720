import { colors } from "@/constants";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  background-color: ${colors.grey4};
`;

export const Title = styled.h4`
  font-weight: 500;
  display: inline-flex;
  color: ${colors.brand.grafite};
  font-size: 14px;
  align-items: center;
  line-height: 19.6px;
  font-family: Roboto, sans-serif;
`;

export const HeadingContainer = styled.div<{ $borderBottom?: boolean }>`
  width: 100%;
  padding-bottom: ${i => i.$borderBottom ? 10: 0}px;
  margin-bottom: ${i => i.$borderBottom ? 10: 0}px;
  border-bottom: ${i => i.$borderBottom ? 1: 0}px solid ${colors.gray.gray20};
`;

export const MapContent = styled.span`
  border-radius: 50%;
  padding: 7px;
  margin-right: 16px;
  display: grid;
  width: 32px;
  height: 32px;
  place-items: center;
  background-color: ${colors.brand.white};
`

export const Tag = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  margin-right: 3px;
  background-color: ${colors.brand.white};
  border-radius: 4px;
  padding: 0px 8px;
  display: grid;
  place-items: center;
  font-weight: 600;
  color: ${colors.brand.grafite};
  text-align: center;
`;
