import { calcPrice } from "@/shared";
import { useDbFunctions } from "@/talons";
import { useCart, useClient } from "@/hooks";
import { useCallback, useRef, useState } from "react";

import {
  ProductProps,
  PriceProductProps,
  PromocaoClientProps,
  TaxSubstitutionProps,
  ParametroPrecoBaseProps,
  PercentualFornecedorProps,
} from "@/types";

export const useCalcPrice = () => {
  const isLoaded = useRef(false);
  const { getLimit } = useCart();
  const { getOnDB } = useDbFunctions();
  const { selectedClient } = useClient();
  const isLoadingCatalog = useRef(false);
  const [catalog, setProducts] = useState([] as ProductProps[]);
  const [substTrib, setSubstTrib] = useState([] as TaxSubstitutionProps[]);

  const handlePrices = useCallback(
    (
      condicoes: number[],
      product: ProductProps,
      parametroPrecoBase: ParametroPrecoBaseProps[],
      percentualFornecedor: PercentualFornecedorProps[],
      listSubstTrib: TaxSubstitutionProps[],
      precoPromocao: Record<number, Record<number, PromocaoClientProps>>,
    ): PriceProductProps[] => {
      const precos = [] as PriceProductProps[];

      for (const cond of condicoes) {
        for (const precoJson of product.PRECO) {

          const embalagem = product.EMBALAGEM?.find(
            (e) => e.QTD_EMBALAGEM === precoJson.QTD_EMBALAGEM
          );

          const priceValidation: any = (precoPromocao?.[selectedClient.COD_CLIENTE]?.[precoJson.COD_PRODUTO]?.VLR_PRECO && precoPromocao?.[selectedClient.COD_CLIENTE]?.[precoJson.COD_PRODUTO]) ?? precoJson;

          const percPreco = parametroPrecoBase?.find(
            (p) =>
              p.NROCONDICAOPAGTO === cond &&
              priceValidation.FAIXAACRFINANCEIRO === p.FAIXAACRFINANCEIRO
          );

          if (percPreco && embalagem) {
            const percentualForcedor =
              percentualFornecedor?.find(
                (forn) => precoJson.SEQFAMILIA === forn.SEQFAMILIA
              )?.PERACRESCDESC || 0;

            const precoPromo = precoPromocao?.[selectedClient.COD_CLIENTE]?.[precoJson.COD_PRODUTO];

            const precoIn = (precoPromocao?.[selectedClient.COD_CLIENTE]?.[precoJson.COD_PRODUTO]?.VLR_PRECO && precoPromo.QTD_EMBALAGEM === embalagem?.QTD_EMBALAGEM) ? {
              ...precoJson,
              DT_FIM: "",
              DT_INICIO: "",
              NROCONDICAOPAGTO: cond,
              TP_PROMO: precoPromo.TP_PROMO,
              VLR_PAUTA: precoJson.VLR_PAUTA,
              DESTACA_ST: precoPromo.DESTACA_ST,
              SEQFAMILIA: precoPromo.SEQFAMILIA,
              COD_CLIENTE: precoPromo.COD_CLIENTE,
              COD_LOJA: selectedClient.COD_LOJA,
              PERACRESCDESC: percentualForcedor,
              COD_PRODUTO: precoJson.COD_PRODUTO,
              SG_EMBALAGEM: embalagem.SG_EMBALAGEM,
              PERACRENTREGA: percPreco.PERACRENTREGA,
              QTD_EMBALAGEM: embalagem.QTD_EMBALAGEM,
              PERACRFINANCEIRO: percPreco.PERACRFINANCEIRO,
              QTD_MULTIPLO_VENDA: embalagem.QTD_MULTIPLO_VENDA,
              FAIXAACRFINANCEIRO: precoPromo.FAIXAACRFINANCEIRO,
              NROTABVENDA: `${selectedClient.COD_TABELA_VENDA}`,
              INDUSAACRESCTABVENDA: precoPromo.INDUSAACRESCTABVENDA,
              COD_TRIBUTACAO_PRODUTO: precoPromo.COD_TRIBUTACAO_PRODUTO,
              VLR_PRECO: precoPromo.VLR_PRECO,
            } : {
              ...precoJson,
              DT_FIM: "",
              DT_INICIO: "",
              NROCONDICAOPAGTO: cond,
              TP_PROMO: precoJson.TP_PROMO,
              VLR_PAUTA: precoJson.VLR_PAUTA,
              DESTACA_ST: precoJson.DESTACA_ST,
              SEQFAMILIA: precoJson.SEQFAMILIA,
              COD_LOJA: selectedClient.COD_LOJA,
              PERACRESCDESC: percentualForcedor,
              COD_PRODUTO: precoJson.COD_PRODUTO,
              SG_EMBALAGEM: embalagem.SG_EMBALAGEM,
              PERACRENTREGA: percPreco.PERACRENTREGA,
              QTD_EMBALAGEM: embalagem.QTD_EMBALAGEM,
              PERACRFINANCEIRO: percPreco.PERACRFINANCEIRO,
              QTD_MULTIPLO_VENDA: embalagem.QTD_MULTIPLO_VENDA,
              FAIXAACRFINANCEIRO: precoJson.FAIXAACRFINANCEIRO,
              NROTABVENDA: `${selectedClient.COD_TABELA_VENDA}`,
              INDUSAACRESCTABVENDA: precoJson.INDUSAACRESCTABVENDA,
              COD_TRIBUTACAO_PRODUTO: precoJson.COD_TRIBUTACAO_PRODUTO,
              VLR_PRECO: precoJson.VLR_PRECO,
            }

            precos.push(precoIn);
          }
        }
      }

      return calcPrice(selectedClient, precos, listSubstTrib);
    },
    [selectedClient]
  );

  const loadStates = useCallback(
    async (
      catalogList: ProductProps[],
      ordered?: boolean,
      filterStock = false,
      forceLoad = false
    ) => {
      if (catalog.length && !forceLoad) return catalogList;

      const codLoja = selectedClient.COD_LOJA;
      const tabVenda = selectedClient.COD_TABELA_VENDA;
      const condicoes = selectedClient.COD_PAGAMENTO.map((i) => i.cod_condicao);

      const listSubstTrib = await getOnDB("substituicaotributaria");
      const parametroPrecoPromocao = await getOnDB("precoPromocaoCliente");

      const parametroPrecoBase = await getOnDB("parametroPrecoBase").then(
        (list) => {
          return list?.filter(
            (p) =>
              `${p.NROTABVENDA}` === `${tabVenda}` &&
              condicoes.includes(p.NROCONDICAOPAGTO) &&
              codLoja === p.COD_LOJA
          );
        }
      );

      const percentualFornecedor = await getOnDB("percentualFornecedor").then(
        (list) => {
          return list?.filter((p) => `${p.NROTABVENDA}` === `${tabVenda}`);
        }
      );

      let list = catalogList.reduce((acc, product) => {
        if (filterStock) {
          const limit = getLimit(product.COD_PRODUTO);
          const emb = product?.EMBALAGEM || []
          const hasEmbLimit = emb.reduce((accEmb, emb) => accEmb || limit >= emb.QTD_EMBALAGEM, false)
          if (limit <= 0 || !emb.length || !hasEmbLimit) return acc;
        }

        product.PRECO = handlePrices(
          condicoes,
          product,
          parametroPrecoBase,
          percentualFornecedor,
          listSubstTrib,
          parametroPrecoPromocao
        );
        if (!product.PRECO.length) return acc;

        return [...acc, product];
      }, [] as ProductProps[]);

      if (ordered) {
        list = list.sort((a, b) => {
          if (a.DESC_PRODUTO < b.DESC_PRODUTO) return -1;
          if (a.DESC_PRODUTO > b.DESC_PRODUTO) return 1;
          return 0;
        });
      }

      setProducts(list);
      setSubstTrib(listSubstTrib);

      isLoaded.current = true;
      return list;
    },
    [getOnDB, handlePrices, getLimit, selectedClient, catalog.length]
  );

  const getProduct = useCallback(
    (id: number) => {
      return catalog?.find((item) => item?.COD_PRODUTO === id);
    },
    [catalog]
  );

  const getCatalog = useCallback(
    async (
      ordered?: boolean,
      timer?: number,
      filterStock = false,
      forceLoad?: boolean
    ) => {
      if (isLoadingCatalog.current || (catalog.length && !forceLoad)) return;
      isLoadingCatalog.current = true;

      const segmentos = selectedClient?.COD_SEGMENTO?.map(c => c.cod_segmento) || []

      const loadedCatalog = await getOnDB("produtos").then(prod => {
        return prod.reduce((acc, p) => {
          p.EMBALAGEM = p.EMBALAGEM?.filter((e) => segmentos.includes(e.COD_SEGMENTO));
          if (!p.EMBALAGEM?.length) return acc

          return [...acc, p];
        }, [] as ProductProps[])
      });

      const list = await loadStates(
        loadedCatalog,
        ordered,
        filterStock,
        forceLoad
      );

      // Timeout inserido para visualização da animação de loading no SVG
      await new Promise((resolve) =>
        setTimeout(() => resolve(true), timer || 1200)
      );

      setProducts(list);
      isLoadingCatalog.current = false;
      return list;
    },
    [catalog, selectedClient, loadStates, getOnDB]
  );

  const reloadCatalog = useCallback(
    async (ordered?: boolean, timer?: number, filterStock = false) => {
      setProducts([]);
      await getCatalog(ordered, timer, filterStock, true);
    },
    [getCatalog]
  );

  const findPrice = (
    price: PriceProductProps[],
    option_uid: string,
    paymentCondition: number
  ) => {
    const [packing, quantity] = option_uid?.split("_");
    const correctPrice = price?.find((p) => {
      if (p?.QTD_MULTIPLO_VENDA > 1) {
        return (
          p.SG_EMBALAGEM === "UN" &&
          p.QTD_MULTIPLO_VENDA?.toString() === quantity &&
          p.NROCONDICAOPAGTO === paymentCondition
        )
      }
      return (
        p.SG_EMBALAGEM === packing &&
        p.QTD_EMBALAGEM?.toString() === quantity &&
        p.NROCONDICAOPAGTO === paymentCondition
      );
    });
    return (
      correctPrice || {} as PriceProductProps
    );
  };

  return {
    catalog,
    isLoaded,
    substTrib,
    isLoadingCatalog,
    getProduct,
    getCatalog,
    reloadCatalog,
    handlePrices,
    findPrice,
  };
};
