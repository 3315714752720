import { colors } from "@/constants";
import styled, { css } from "styled-components";

export const CardContainer = styled.div<{ isLast?: boolean }>`
  border: 1px solid white;
  border-radius: 6px;
  margin: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 0px 6px 4px rgba(45, 45, 45, .15);
  flex-direction: column;
  ${(props) => props.isLast && css`
    margin-bottom: 80px;
  `}
`;

export const SubContainer = styled.div<{ direction?: string }>`
  background-color: ${colors.brand.white};
  padding: ${(props) => props.direction ? 0 : 12}px;
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: ${(props) =>  props.direction || 'column'};
  justify-content: space-between;
  align-items: stretch;
  ${(props) =>  props.direction === 'row' && css`
    justify-content: center;
  `};
`;

export const IconContainer = styled.div<{ backgroundColor?: string }>`
  width: 32px;
  height: 32px;
  display: flex;

  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${(props) =>  props.backgroundColor || colors.gray.gray10};
`;

export const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 4fr;
  flex-direction: row;
  align-items: center;
  /* gap: 24px; */
  justify-content: space-between;
`;

export const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 15fr;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 6px;
`;

export const RegularTag = styled.span<{ backgroundColor?: string; color?: string; width?: string; justifyEnd?: boolean; }>`
  border-radius: 3px;
  color: ${(props) => props.color || colors.brand.grafite};
  font-size: 11px;
  font-weight: 500;
  padding: 3px 8px;
  ${(props) => props.width && css`
    width: fit-content;
  `}
  ${(props) => props?.justifyEnd ? css`
    display: flex;
    align-self: center;
    justify-self: flex-end;
    flex-wrap: wrap;
  ` : css`
    text-align: center;
  `}
  /* text-align: center; */
  line-height: 13px;
  background-color: ${(props) => props.backgroundColor || colors.gray.gray10};
  font-family: Roboto, sans-serif;
  margin-top: 6px;
`;

export const OrderNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const OrderNumberSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const OrderNumber = styled.span<{ color?: string; weight?: number }>`
  font-size: 12px;
  font-weight: ${(props) => props.weight || 400};
  font-family: Roboto, sans-serif;
  color: ${(props) => props.color || colors.grey3};
`;

export const Divider = styled.div<{ size?: number }>`
  width: ${(props) => `${props.size}px` || '32px' };
`;

export const SeparatorContainer = styled.div`
  padding: 0 20px;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${colors.background1};
`;

export const BlockContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 5fr;
  align-items: center;
  justify-content: space-evenly;
`;
