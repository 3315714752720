import styled, { css } from "styled-components";
import { colors } from "@/constants";
import { Link } from "react-router-dom";

export const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  padding: 20px;

  background-color: ${colors.brand.white};
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CallToAction = styled.span`
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  color: ${colors.brand.grafite};
`;

export const Message = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${colors.gray.gray50};
`;

export const Error = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: ${colors.red3};
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormController = styled.div`
  width: 100%;
`;

export const SmallText = styled.div`
  text-align: right;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: ${colors.gray.gray50};
`;

export const TextAction = styled.span`
  font-weight: 500;
  color: ${colors.gray.gray60};
  cursor: pointer;
`;

export const ContinueButton = styled.button<{ $loading?: boolean }>`
  width: 100%;
  height: 55px;
  padding: 18px;

  background-color: ${colors.green};
  border: solid 1px ${colors.green};
  border-radius: 10px;

  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  color: ${colors.brand.white};
  line-height: 18.75px;

  &:disabled {
    background-color: ${colors.gray.gray10};
    border: none;

    color: ${colors.gray.gray40};
  }

  @keyframes loading {
    0% {
      left: -50%;
      width: 45%;
      opacity: 1;
    }
    42% {
      left: 12%;
      width: 20%;
      opacity: .4;
    }
    100% {
      width: 83%;
      left: 100%;
      opacity: 1;
    }
  }

  ${i => i.$loading &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 2px;
        width: 25%;
        background-color: ${colors.green};
        animation: loading 1800ms infinite;
      }
    `
  }
`;
