import React from 'react';
import * as S from './styles';

import { RiSignalWifiOffLine, RiErrorWarningLine, RiLoopLeftLine, RiLoopRightLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineNavigateNext } from "react-icons/md";
import { colors } from '@/constants';

import { OrderItemsProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import { formatCurrency } from '@/shared';

const StatusOrder: Record<string, { icon: any, backgroundColor: string, message: string, color: string, tag?: string }> = {
  'indisponivel': {
    icon: <RiSignalWifiOffLine size={18} color={colors.brand.grafite} />,
    backgroundColor: colors.gray.gray10,
    message: 'Sem conexão',
    tag: ' [ SINCRONIZAR ]',
    color: colors.brand.grafite,
  },
  'faturado': {
    icon: <FaCheck size={18} color={colors.alert.green300} />,
    backgroundColor: colors.alert.green100,
    message: 'Pedido faturado',
    tag: ' [ FATURADO ]',
    color: colors.alert.green300,
  },
  'integrado': {
    icon: <FaCheck size={18} color={colors.alert.green300} />,
    backgroundColor: colors.alert.green100,
    message: 'Pedido integrado',
    tag: '',
    color: colors.alert.green300,
  },
  'separacao': {
    icon: <FaCheck size={18} color={colors.alert.green300} />,
    backgroundColor: colors.alert.green100,
    message: 'Pedido em separação',
    tag: '',
    color: colors.alert.green300,
  },
  'entregue': {
    icon: <FaCheck size={18} color={colors.alert.green300} />,
    backgroundColor: colors.alert.green100,
    message: 'Pedido entregue',
    tag: '',
    color: colors.alert.green300,
  },
  'emitido': {
    icon: <FaCheck size={18} color={colors.alert.green300} />,
    backgroundColor: colors.alert.green100,
    message: 'Pedido emitido',
    tag: '',
    color: colors.alert.green300,
  },
  'cancelado': {
    icon: <RiErrorWarningLine size={18} color={colors.brand.grafite} />,
    backgroundColor: colors.alert.red100,
    message: 'Pedido cancelado',
    color: colors.alert.red200,
  },
  'reprocessar': {
    icon: <RiLoopLeftLine size={18} color={colors.alert.orange300} />,
    backgroundColor: colors.alert.orange100,
    message: 'Reprocessar',
    tag: ' [ SEM ESTOQUE ]',
    color: colors.alert.orange300,
  },
  'processando': {
    icon: <RiLoopRightLine size={18} color={colors.brand.grafite} />,
    backgroundColor: '',
    message: 'Em fila de processamento',
    color: '',
  },
  'roterizacao': {
    icon: <RiLoopRightLine size={18} color={colors.brand.grafite} />,
    backgroundColor: '',
    message: 'Em roterização',
    color: '',
  },
}

const OrderStatusCard = ({
  order,
  status,
  toggleModal,
  isLast }:
  {
    order: OrderItemsProps;
    status?: string;
    toggleModal?: (status: string) => void;
    isLast?: boolean
  }) => {

  return (
    <React.Fragment>

      <S.SubContainer onClick={() => {
        if (toggleModal) return toggleModal(order?.orderNumber)
        return null;
      }}>
        <S.TopContainer>
          <S.IconContainer backgroundColor={StatusOrder?.[status || order?.status]?.backgroundColor}>
            {StatusOrder?.[status || order?.status]?.icon}
          </S.IconContainer>

          <S.OrderNumberContainer>
            <S.OrderNumber>
              Nº do pedido:
            </S.OrderNumber>

            <S.OrderNumber color={colors.brand.grafite} weight={700}>
              {order?.orderNumber || '----'}
            </S.OrderNumber>
          </S.OrderNumberContainer>

          <S.OrderNumberSubContainer>
            <S.OrderNumberContainer>
              <S.OrderNumber>
                {order?.erpNumber ? 'Nº do pedido ERP:' : !toggleModal && order?.erpNumberClient ? 'Nº do pedido ERP Cliente:' : 'Nº do pedido ERP:'}
              </S.OrderNumber>

              <S.OrderNumber color={colors.brand.grafite} weight={700}>
                {order?.erpNumber ? order?.erpNumber : !toggleModal && order?.erpNumberClient ? order?.erpNumberClient : '----'}
              </S.OrderNumber>
            </S.OrderNumberContainer>

            {toggleModal && <MdOutlineNavigateNext color={colors.brand.grafite} size={24} style={{ marginLeft: 12 }} onClick={() => toggleModal(order?.orderNumber)} />}
          </S.OrderNumberSubContainer>
        </S.TopContainer>

        <S.TopContainer>
          <div style={{ width: 32 }} />
          <S.OrderNumberContainer>
            <S.OrderNumber>
              Valor Total:
            </S.OrderNumber>

            <S.OrderNumber color={colors.brand.grafite} weight={700}>
              {formatCurrency({ value: order.total })}
            </S.OrderNumber>
          </S.OrderNumberContainer>

          {order?.vlr_comissao && <S.OrderNumberContainer>
            <S.OrderNumber>
              Comissão:
            </S.OrderNumber>

            <S.OrderNumber color={colors.brand.grafite} weight={700}>
              {formatCurrency({ value: order.vlr_comissao })}
            </S.OrderNumber>
          </S.OrderNumberContainer>}
        </S.TopContainer>

        <S.BottomContainer>
          <S.Divider size={36} />
          <S.BlockContainer>
            <S.RegularTag width={'fit-content'}>{Number(order?.quantity) > 1 ? `${order?.quantity} Itens` : `${order?.quantity} Item`}</S.RegularTag>
            <S.Divider size={10} />
            <S.RegularTag justifyEnd color={StatusOrder?.[status || order?.status]?.color} backgroundColor={StatusOrder?.[status || order?.status]?.backgroundColor}>
              {StatusOrder?.[status || order?.status]?.message}
              <span style={{ marginLeft: StatusOrder?.[status || order?.status]?.tag ? 3 : 0 }}>
                {StatusOrder?.[order?.status]?.tag}
              </span>
            </S.RegularTag>

          </S.BlockContainer>
        </S.BottomContainer>

        <S.BottomContainer>

        </S.BottomContainer>
        {!isLast && <S.Divider color={colors.background1} />}

      </S.SubContainer>
    </React.Fragment>
  )
}

export default OrderStatusCard;