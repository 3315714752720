import { colors } from "@/constants";
import styled, { css } from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
`;

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  padding: 24px;
  color: ${colors.brand.grafite};
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.grey4};
`;

export const ModalContent =  styled.div`
  padding: 20px;
  min-height: 75vh;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Heading = styled.div`
  display: grid;
  flex-direction: row;

`;

export const ProductIdGrid = styled.div`
  display: grid;
  grid-template-columns:  1fr 4fr;
  align-items: flex-start;
  gap: 12px;
`;

export const ProductImage = styled.img`
  height: 61px;
  width: 61px;
`;

export const ProductImageWrapper = styled.div<{ $hasExpiring?: boolean }>`
  border: 1px solid ${({ $hasExpiring }) => $hasExpiring ? colors.alert.orange300 : colors.background1};
  border-radius: 6px;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExpiringView = styled.div`
  gap: 10px;
  padding: 12px;
  align-items: center;
  display: inline-flex;
  border-radius: 6px;
  border-left-width: 6px !important;
  background-color: ${colors.alert.orange50};
  border: 1px solid ${colors.alert.orange300};
`

export const ExpiringText = styled.p`
  color: ${colors.alert.orange300};
`

export const ProductName = styled.span<{ $hasExpiring?: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  text-align: left;
  color: ${({ $hasExpiring }) => $hasExpiring ? colors.alert.orange300 : colors.brand.grafite};
`;

export const ProductTable = styled.div`
  border: 1px solid ${colors.gray.gray10};
  border-radius: 6px;
  width: 100%;
  padding: 12px;
  margin-bottom: 100px;
`;

export const TableBody = styled.div``;

export const TableRow = styled.div<{ $applyBackground?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 3fr;
  min-height: 45px;
  padding: 12px;
  gap: 12px;
  border-radius: 6px;
  ${(props) => props.$applyBackground && css`
      background-color: ${colors.grey4};
  ` };
`;

export const TableCell = styled.div<{ $bold?: string; $capitalize?: boolean }>`
  font-family: Roboto, sans-serif;
  font-weight: ${props => props.$bold ? 700 : 500 };
  font-size: 14px;
  color: ${colors.brand.grafite};
  text-align: ${props => props.$bold ? 'left' : 'right'};
  ${props => props.$capitalize && css`
    text-transform: capitalize;
  `};
`;