import * as S from "./styles";
import { useMemo } from "react";
import { colors } from "@/constants";
import { ToolTip } from "@/components";
import { formatCurrency } from "@/shared";
import { useDiscountAmountFlex, useUserContext } from "@/hooks";
import { BetinhoTheRobot } from "@/components";

import {
  USER_DATA,
  MY_CLIENTS,
  ORDER_STATUS_PAGE,
} from "@/constants/systemRoutes";

const listMenu = [USER_DATA, ORDER_STATUS_PAGE, MY_CLIENTS];

const HeaderComp = () => {
  const { discountAmount } = useDiscountAmountFlex();
  const { customer: rcaInfos, rcaSaldoFlex } = useUserContext();

  const hour = new Date().getHours();
  const morning = hour > 6 && hour < 12;
  const night = hour >= 18 || hour < 6;
  const labelWelcome = morning ? "Bom dia" : night ? "Boa noite" : "Boa tarde";

  const saldoEstimado = useMemo(() => {
    const value = rcaSaldoFlex || 0;
    const inteiro = Math.trunc(value);
    const decimal = Math.abs((value - inteiro) * 100)
      .toFixed(0)
      .padStart(2, "0");

    const formatted = Number(`${inteiro}.${decimal}`);

    return formatCurrency({ value: formatted || 0 });
  }, [rcaSaldoFlex]);

  const saldoEstimadoCarrinhos = useMemo(() => {
    return formatCurrency({ value: (rcaSaldoFlex || 0) + discountAmount });
  }, [rcaSaldoFlex, discountAmount]);

  const saldoERP = useMemo(
    () => formatCurrency({ value: rcaInfos?.saldoERP || 0 }),
    [rcaInfos]
  );

  const Items = useMemo(() => {
    return listMenu.map(({ ...item }: any, ind) => (
      <S.BgFastLinks
        key={item.id}
        to={(!item.disabled && item.link) || "#"}
        data-test={`dashboards-fastLinks-link-${item.id}`}
      >
        {item.icon}
        <S.FastLinkLabel>{item.title}</S.FastLinkLabel>
      </S.BgFastLinks>
    ));
  }, []);

  return (
    <S.Content>
      <S.BgDark>
        <S.PaddingContent>
          <S.Header>
            <S.DataInfos>
              <S.Image>
                <S.Sigla>{rcaInfos?.firstname?.charAt(0)}</S.Sigla>
              </S.Image>

              <S.NameContent>
                <S.GoodDay>{labelWelcome},</S.GoodDay>
                <S.Name>{rcaInfos?.firstname}</S.Name>
              </S.NameContent>
            </S.DataInfos>
            {/* <S.Notifications>
              {rcaInfos?.notificacoes && <S.Alert />}
              <S.ChatLine size={28} />
            </S.Notifications> */}
          </S.Header>

          <S.Balance>
            <S.ContentBalance>
              <S.BalanceLabel>
                Saldo
                <S.BalanceLabelBold>Estimado</S.BalanceLabelBold>:
                <ToolTip
                  time={15000}
                  direction="top"
                  displayMode="click"
                  bgcolor={colors.grey7}
                  text={`Saldo estimado calculado com base no somatório dos carrinhos: ${saldoEstimadoCarrinhos}`}
                >
                  <S.InformationIcon size={16} />
                </ToolTip>
              </S.BalanceLabel>

              <S.BalanceValue>
                <S.BalanceValueRealce>{saldoEstimado}</S.BalanceValueRealce>
              </S.BalanceValue>
            </S.ContentBalance>

            <S.ContentBalance>
              <S.BalanceLabel direction="right">
                Saldo
                <S.BalanceLabelBold>ERP</S.BalanceLabelBold>:
              </S.BalanceLabel>

              <S.BalanceValueERP>{saldoERP}</S.BalanceValueERP>
            </S.ContentBalance>
          </S.Balance>

          <S.News className="disabled-service">
            <S.AlertNews>Em breve</S.AlertNews>

            <S.LeftColumnNews>
              <S.NewsTitle>
                BF Apo<S.NewsTitlwSpan>IA</S.NewsTitlwSpan>
              </S.NewsTitle>

              <S.NewsText>
                Nossa IA detectou clientes que não colaboraram neste ciclo.
              </S.NewsText>

              <S.NewsButton>Aumente seus ganhos</S.NewsButton>
            </S.LeftColumnNews>

            <S.RightColumnNews className="right-column-news">
              <BetinhoTheRobot
                size="small"
                message="Olá, melhore seus ganhos! 👋"
                showMessage
              />
            </S.RightColumnNews>
          </S.News>
        </S.PaddingContent>
      </S.BgDark>

      <S.PaddingContent>
        <S.FastLinks>
          <S.BgDivisor>{Items}</S.BgDivisor>
        </S.FastLinks>
      </S.PaddingContent>
    </S.Content>
  );
};

export default HeaderComp;
