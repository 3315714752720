import Infos from './infos';
import Header from './header';
import * as S from './styles';
import MenuList from './list';
// import { useEffect } from 'react';
// import { getOnStorage, setOnStorage } from '@/shared';
// import Metricas from "./metricas";
// import FastLinks from "./fast-links";

const DashboardPaage = () => {

  // useEffect(() => {
  //   const isFirstLoad = getOnStorage('is_first_load')
  //   if (isFirstLoad) return

  //   navigator.serviceWorker.getRegistrations().then(registrations => {
  //     registrations.forEach(registration => {
  //       registration.unregister().then(() => {
  //         document.cookie = 'Clear-Site-Data: "cache", "cookies", "storage", "executionContexts"';
  //         setOnStorage('is_first_load', 'true')
  //         window.location.reload();
  //       }).catch(error => {
  //         console.error('Service worker unregistration failed:', error);
  //       });
  //     });
  //   }).catch(error => {
  //     console.error('Service worker registration retrieval failed:', error);
  //   });
  // }, []);

  return (
    <S.Container className="dashboards">
      <Header />
      {/* <FastLinks /> */}
      {/* <Metricas /> */}
      <S.Title>Menus</S.Title>
      <MenuList />
      <Infos />
    </S.Container>
  );
};

export default DashboardPaage;
