import Empty from './empty';
import * as S from './styles';
import { cleanStr } from '@/shared';
import { searchTerms } from '@/constants';
import { Directions, FilterItemProps } from '@/types';
import { useCalcPrice, useScrollPageEvent } from '@/talons';
import { useCart, useClient, useDiscountFlex } from '@/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Search, FilterBar, Product, Loading } from '@/components';

const SEARCH_TERMS = searchTerms.reduce((acc, groupedTerms) => {
  const mapedTerms = groupedTerms.reduce((ac, term) => ({ ...ac, [cleanStr(term)]: groupedTerms.join(' ') }), {})
  return { ...acc, ...mapedTerms }
}, {}) as Record<string, string>

const PREPOSICOES = ['em', 'de', 'da', 'do']

const SearchPage = () => {
  const { selectedClient } = useClient();
  const { discounts } = useDiscountFlex();
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { page, setPage } = useScrollPageEvent();
  const [direction, setDirection] = useState('grid' as Directions);
  const { catalog, isLoaded, substTrib, getCatalog } = useCalcPrice();
  const [selectedFilters, setFilters] = useState([] as FilterItemProps[]);
  const { addProductToCart, updateProductAmount, removeProductFromCart } =
    useCart();

  useEffect(() => {
    setPage(1);
    setIsLoading(true)
    
    setTimeout(() => {
      setIsLoading(false)
    }, 200);
  }, [selectedFilters, search, setPage]);

  useEffect(() => {
    if (isLoaded.current) return;

    getCatalog(true);
    document.getElementById('field-search')?.focus();
  }, [getCatalog, isLoaded]);

  const handleSearch = useCallback(
    (val: string) => {
      setSearch(val);
      setPage(1);
      setFilters([]);
    },
    [setPage]
  );

  const searchList = useMemo(() => {
    const filter = cleanStr(search ?? '')
    if (!filter) return catalog;

    if (filter.toLowerCase() === 'saldoflex') {
      const client = discounts?.[selectedClient.COD_CLIENTE] || {};
      const list = Object.keys(client).map((item) => item.split('-')[0]);
      return catalog?.filter((p) => list.includes(`${p.COD_PRODUTO}`));
    }

    // ean | seqproduto | descricao | familia | terms
    const termos = cleanStr(SEARCH_TERMS?.[filter] ?? filter)
    const searchTermValues = termos.split(' ').filter(i => !PREPOSICOES.includes(i)) || [];

    return catalog?.filter((i) => {
      const ean = i.ATRIBUTOS.find((atributo) => 'ean_grp' in atributo)?.ean_grp ?? '';
      const familiaGrp = i.ATRIBUTOS.find((atributo) => 'familia_grp' in atributo)?.familia_grp ?? '';
      const searchable = cleanStr(`${ean} ${i.COD_PRODUTO} ${i.DESC_PRODUTO} ${familiaGrp}`);
      
      return searchTermValues.some(term => searchable.includes(term));
    }).sort((a, b) => {
      const aTerms = cleanStr(a.DESC_PRODUTO).split(' ');
      const bTerms = cleanStr(b.DESC_PRODUTO).split(' ');

      const aIncludesMatch = aTerms.includes(filter);
      const bIncludesMatch = bTerms.includes(filter);
      
      if (aIncludesMatch && !bIncludesMatch) return -1;
      if (!aIncludesMatch && bIncludesMatch) return 1;
      if (aIncludesMatch !== bIncludesMatch) return aIncludesMatch ? -1 : 1;

      const aMatchCount = searchTermValues.filter(term => aTerms.includes(term)).length;
      const bMatchCount = searchTermValues.filter(term => bTerms.includes(term)).length;

      if (aMatchCount !== bMatchCount) return bMatchCount - aMatchCount;

      return (bIncludesMatch ? 1 : 0) - (aIncludesMatch ? 1 : 0);
    });
  }, [catalog, search, discounts, selectedClient.COD_CLIENTE]);

  const filteredList = useMemo(() => {
    if (!selectedFilters.length) return searchList;

    const ids = selectedFilters.map((i) => i.id);

    return searchList.filter((i) => {
      const isBrandFilter = selectedFilters.some((f) => f.id === i.COD_MARCA);
      if (isBrandFilter) return true;

      const isCatFilter = selectedFilters.find((f) => f.id === i.COD_CATEGORIA);

      return (isCatFilter && !isCatFilter?.brands?.some((item) => ids.includes(item)));
    });
  }, [searchList, selectedFilters]);

  const count = useMemo(() => filteredList?.length, [filteredList]);
  const isPlural = useMemo(() => (count !== 1 ? 's' : ''), [count]);

  return (
    <S.Content id='search-page'>
      <Search
        value={search}
        showPaymentMode
        id='search-page'
        showCancelButton
        setFilter={handleSearch}
      />

      {!catalog.length || isLoading ? (
        <S.ContentLoading>
          <Loading scale={2} />
        </S.ContentLoading>
      ) : (
        <S.Container>
          {!!count && (
            <>
              <FilterBar
                direction={direction}
                products={searchList}
                filters={selectedFilters}
                setFilters={setFilters}
                setDirection={setDirection}
              />

              <S.Separator />

              <S.Counter>
                {count} produto{isPlural} encontrado{isPlural}
              </S.Counter>
            </>
          )}

          <S.ContentList $isgrid={direction === 'grid'}>
            {filteredList?.slice(0, page * 6).map((product, ind) => (
              <Product
                product={product}
                substTrib={substTrib}
                direction={direction}
                key={`search-product-${product.COD_PRODUTO}-${ind}`}
                updateProductAmount={updateProductAmount}
                removeProductFromCart={removeProductFromCart}
                addProductToCart={addProductToCart}
              />
            ))}
          </S.ContentList>

          {!count && <Empty text={search} />}
        </S.Container>
      )}
    </S.Content>
  );
};

export default SearchPage;
