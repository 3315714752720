const equivalientes = [
  ['aerozol', 'aerossol', 'aerosol'],
  ['bis', 'biz'],
  ['bombriu', 'bombril', 'esponja de aço'],
  ['bolacha', 'biscoito'],
  ['bubbaloo', 'bubbalu', 'bubalu', 'babalu'],
  ['capuccino', 'cappuccino', 'caputino'],
  ['catchup', 'ketchup', 'quetchup', 'katchup'],
  ['cerveja', 'chopp', 'chop'],
  ['cotonete', 'cotonetes', 'hastes flexíveis'],
  ['detergente', 'lava louça', 'lava louças'],
  ['dois frades', 'padre', 'chocolate do padre'],
  ['filme pvc', 'papel filme'],
  ['filtro papel', 'filtro de papel', 'filtro de café', 'coador', 'coador de papel'],
  ['havaiana', 'havaianas', 'havalanas', 'avalanas'],
  ['ipe', 'ipê', 'ypê'],
  ['lava roupa', 'lava roupas', 'sabão em pó'],
  ['maisena', 'maizena', 'amido'],
  ['massa de tomate', 'molho de tomate'],
  ['perfex', 'pano multiuso'],
  ['pinga', 'cachaça', 'aguardente'],
  ['preservativo', 'camisinha'],
  ['red bull', 'energético'],
  ['sandália', 'chinelo'],
  ['suco em pó', 'refresco em pó', 'refresco', 'suco'],
  ['toddynho', 'todinho', 'todynho'],
]

export default equivalientes