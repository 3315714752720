import { Modal } from "@/components";
import ContentModalFlex from "./ContentModalFlex";

import {
  ProductProps,
  PackagesTypesProps,
  TaxSubstitutionProps,
} from "@/types";

interface Props {
  product: ProductProps;
  isOpenedModal: boolean;
  pack: PackagesTypesProps;
  substTrib: TaxSubstitutionProps[];
  openModal: (val: boolean) => void;
  setPackage: (val: PackagesTypesProps) => void;
  packQty: number;
  setPackQuantity: (val: number) => void;
}

const ModalFlexDiscount = ({
  pack,
  product,
  substTrib,
  openModal,
  setPackage,
  isOpenedModal,
  packQty,
  setPackQuantity,
}: Props) => {
  return (
    <Modal
      // fullHeight
      id="flex-discount"
      isOpen={isOpenedModal}
      closeModal={openModal}
    >
      {isOpenedModal && (
        <ContentModalFlex
          pack={pack}
          packQty={packQty}
          setPackQuantity={setPackQuantity}
          product={product}
          substTrib={substTrib}
          openModal={openModal}
          setPackage={setPackage}
          isOpenedModal={isOpenedModal}
        />
      )}
    </Modal>
  );
};

export default ModalFlexDiscount;
