import * as S from "./styles";
import { useCalcPrice } from "@/talons";
import { useCallback, useState } from "react";
import { PackagesTypesProps, ProductProps } from "@/types";
import { ModalCustomAttributes, ModalFlexDiscount, Product, Slider } from "@/components";

interface Products {
  id: string;
  dots?: boolean;
  products: ProductProps[];
  addProductToCart: ({
    sku,
    uid,
    option_uid,
    quantity,
    category_id,
  }: {
    sku: number;
    uid: string;
    quantity: number;
    option_uid: string;
    category_id: number;
  }) => void;
  updateProductAmount: ({
    sku,
    uid,
    quantity,
  }: {
    sku: number;
    uid: string;
    quantity: number;
  }) => void;
  removeProductFromCart: ({ uid }: { uid: string }) => void;
}

const RenderSliderProducts = ({
  products,
  id,
  dots,
  addProductToCart,
  removeProductFromCart,
  updateProductAmount,
}: Products) => {
  const { substTrib } = useCalcPrice();
  const [isOpenedModal, setOpenModal] = useState(false);
  const [openAttributesModal, setOpenAttributesModal] = useState(false);
  const [packQuantity, setPackQuantity] = useState(0);
  const [packaging, setPackage] = useState('' as PackagesTypesProps);
  const [product, setProduct] = useState(null as ProductProps | null);

  const handleModalInfos = useCallback((product: ProductProps) => {
    setProduct(product);
    setTimeout(() => setOpenAttributesModal(true), 300);
  }, [setProduct, setOpenAttributesModal])

  const handleModalFlex = useCallback((product: ProductProps) => {
    const embalagem = product.EMBALAGEM?.[0] || {}
    setProduct(product)
    setPackage(embalagem.SG_EMBALAGEM);
    setPackQuantity(embalagem.QTD_EMBALAGEM);
    setTimeout(() => setOpenModal(true), 300);
  }, [setProduct, setPackage, setPackQuantity]);

  const promocoes = products?.map((item: ProductProps, ind: number) => (
    <S.ProductContainer key={`${id}-${ind}`}>
      <Product
        showTime={false}
        product={{ ...item }}
        handleModalFlex={handleModalFlex}
        handleModalInfos={handleModalInfos}
        addProductToCart={addProductToCart}
        updateProductAmount={updateProductAmount}
        removeProductFromCart={removeProductFromCart}
      />
    </S.ProductContainer>
  )) || [];

  return (
    <>
      <Slider
        id={id}
        canSwipe
        dots={dots}
        slides={promocoes}
        slidesToShow="auto"
        className="promotional"
      />

      {product && packaging && <ModalFlexDiscount
        pack={packaging}
        product={product}
        packQty={packQuantity}
        substTrib={substTrib}
        isOpenedModal={isOpenedModal}
        setPackage={setPackage}
        openModal={setOpenModal}
        setPackQuantity={setPackQuantity}
      />}

      {product && (
        <ModalCustomAttributes
          product={product}
          isOpen={openAttributesModal}
          openModal={setOpenAttributesModal}
        />
      )}
    </>
  );
};

export default RenderSliderProducts;
