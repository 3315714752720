import { isSameDay } from "date-fns";
import { ProductProps } from "@/types";
import { useEffect, useMemo, useState } from 'react';
import { getOnStorage, setOnStorage } from "@/shared";
import { useFetchAxios, useNetworkStatusContext, useUserContext } from "@/hooks";
import { useDbFunctions } from "../dbFunctions/useDBFunctions";

export interface OrderProps {
  clientName: string;
  clientRefNumber: string;
  clientCnpj: string;
  orders: OrderItemsProps;
}
export interface OrderItemsProps {
  orderNumber: string;
  erpNumberClient?: string;
  erpNumber: string;
  origin: string;
  orderNumberMagento: string;
  quantity: number | string;
  status: string;
  reason: string;
  orderDate: string;
  total: string;
  vlr_comissao?: number;
  paymentTerms: string;
  actionsPending: ItemsProps[];
}
export interface ItemsProps extends ProductProps {
  cod_segmento: number;
  sku: string;
  product: string;
  price: string;
  unitPrice: string;
  unitPricePerItem: string;
  stPrice: string;
  stPricePackaging: number;
  integrationPrice: number;
  integrationPriceFlex: number;
  quantity: number;
  quantityMultiplo: number;
  percentualFlex?: string;
  packaging: string;
  status: string;
  reason: string;
}

export const useOrderStatusPage = () => {
  const { customer } = useUserContext();
  const { getOnDB } = useDbFunctions();
  const { axiosCtx: axiosInstance } = useFetchAxios();
  const { connectivityStatus } = useNetworkStatusContext();

  const clientsMap = new Map();
  const clientsList = [] as string[];

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const [filterDate, setFilterDate] = useState<Date>();

  const [orders, setOrders] = useState<OrderProps[]>([]);
  const [products, setProducts] = useState<Record<number, ProductProps>>({});
  const [offlineOrders, setOfflineOrders] = useState<OrderProps[]>([]);
  const [synchedOrdersList, setSynchedOrdersList] = useState<OrderProps[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`${window.agp_config.REACT_APP_AWS}/pedido-notifica-get?seqpessoa=${customer.codPessoa}`)
          .then(res => res)
          .catch(e => {
            console.log('deu ruim: ', e);
            return e;
          });

        const productsDB = await getOnDB("dicionario_produtos");
        setProducts(productsDB);
        setData(response?.data?.pedidos);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchOfflineData = async () => {
      const synchedOrders = getOnStorage("synched_orders");
      const offlineOrdersStr = getOnStorage("offline_orders");
      const productsDB = await getOnDB("dicionario_produtos");

      if (offlineOrdersStr) {
        const ordersOffline = offlineOrdersStr?.map((order: any) => {
          return {
            clientName: order?.company?.name,
            clientRefNumber: order?.company?.id,
            clientCnpj: order?.company?.cnpj,
            orders: {
              orderNumber: order?.orders?.order_number,
              erpNumber: order?.orders?.erp_order_number,
              orderNumberMagento: order?.orders?.order_number_magento,
              quantity: order?.orders?.items,
              status: 'indisponivel',
              reason: order?.orders?.reason,
              orderDate: order?.orders?.order_date,
              total: order?.orders?.total,
              vlr_comissao: order?.orders?.vlr_comissao,
              paymentTerms: order?.orders?.payment_terms,
              actionsPending: order?.orders?.actions_pending?.map((item: any) => ({
                cod_segmento: item?.cod_segmento,
                sku: item?.sku,
                product: item?.product,
                price: item?.price,
                unitPrice: item?.unit_price,
                unitPricePerItem: item?.unit_price_per_item,
                stPrice: item?.st_price,
                stPricePackaging: item?.st_price_packaging,
                integrationPrice: item?.integration_price,
                integrationPriceFlex: item?.integration_price_flex,
                quantity: item?.quantity,
                quantityMultiplo: item?.quantity_multiplo,
                packaging: item?.packaging,
                percentualFlex: item?.percentual_flex,
                status: '',
                reason: '',
                ...productsDB[item?.sku],
              }))
            }
          }
        });
        setOfflineOrders(ordersOffline);
      }
      if (synchedOrders) {

        const synchedList = synchedOrders?.map((order: any) => {
          return {
            clientName: order?.company?.name,
            clientRefNumber: order?.company?.id,
            clientCnpj: order?.company?.cnpj,
            orders: {
              orderNumber: order?.orders?.order_number,
              erpNumber: order?.orders?.erp_order_number,
              erpNumberClient: order?.orders?.erp_order_number_client,
              orderNumberMagento: order?.orders?.order_number_magento,
              quantity: order?.orders?.items,
              status: 'processando',
              reason: order?.orders?.reason,
              orderDate: order?.orders?.order_date,
              total: order?.orders?.total,
              vlr_comissao: order?.orders?.vlr_comissao,
              paymentTerms: order?.orders?.payment_terms,
              actionsPending: order?.orders?.actions_pending?.map((item: any) => ({
                sku: item?.sku,
                product: item?.product,
                price: item?.price,
                unitPrice: item?.unit_price,
                unitPricePerItem: item?.unit_price_per_item,
                stPrice: item?.st_price,
                stPricePackaging: item?.st_price_packaging,
                integrationPrice: item?.integration_price,
                integrationPriceFlex: item?.integration_price_flex,
                quantity: item?.quantity,
                quantityMultiplo: item?.quantity_multiplo,
                packaging: item?.packaging,
                percentualFlex: item?.percentual_flex,
                status: '',
                reason: '',
                ...productsDB[item?.sku],
              }))
            }
          }
        });
        if (synchedOrders?.length && orders?.length) {
          orders?.forEach((item: any) => {
            const findIndex = synchedOrders?.findIndex((order: any) => order?.company?.id === item?.clientRefNumber && order?.orders?.order_number === item?.orders?.orderNumber);
            if (findIndex > -1) {
              synchedOrders?.splice(findIndex, 1);
              setOnStorage("synched_orders", synchedOrders);
              synchedList?.splice(findIndex, 1);
            }
          })
        }
        setSynchedOrdersList(synchedList);
      }
    };
    fetchOfflineData();
  }, [getOnDB]);

  useEffect(() => {
    if (data && products) {
      const synchedOrders = getOnStorage("synched_orders");
      const synchedList = synchedOrders?.map((order: any) => {
        return {
          clientName: order?.company?.name,
          clientRefNumber: order?.company?.id,
          clientCnpj: order?.company?.cnpj,
          orders: {
            orderNumber: order?.orders?.order_number,
            erpNumber: order?.orders?.erp_order_number,
            orderNumberMagento: order?.orders?.order_number_magento,
            quantity: order?.orders?.items,
            status: 'processando',
            reason: order?.orders?.reason,
            orderDate: order?.orders?.order_date,
            total: order?.orders?.total,
            vlr_comissao: order?.orders?.vlr_comissao,
            paymentTerms: order?.orders?.payment_terms,
            actionsPending: order?.orders?.actions_pending?.map((item: any) => ({
              sku: item?.sku,
              product: item?.product,
              price: item?.price,
              unitPrice: item?.unit_price,
              unitPricePerItem: item?.unit_price_per_item,
              stPrice: item?.st_price,
              stPricePackaging: item?.st_price_packaging,
              integrationPrice: item?.integration_price,
              integrationPriceFlex: item?.integration_price_flex,
              quantity: item?.quantity,
              quantityMultiplo: item?.quantity_multiplo,
              packaging: item?.packaging,
              percentualFlex: item?.percentual_flex,
              status: '',
              reason: '',
              ...products[item?.sku],
            }))
          }
        }
      });
      const orders = (data as any[])?.map((order: any) => {
        if (!clientsMap?.has(order?.company?.id)) {
          clientsMap.set(order?.company?.id, {
            clientName: order?.company?.name,
            clientRefNumber: order?.company?.id,
            clientCnpj: order?.company?.cnpj,
            orders: [{
              orderNumber: order.orders?.order_number,
              erpNumber: order?.orders?.erp_order_number,
              orderNumberMagento: order?.orders?.order_number_magento,
              quantity: order?.orders?.items,
              status: order?.orders?.status,
              reason: order?.orders?.reason,
              orderDate: order?.orders?.order_date,
              total: order?.orders?.total,
              vlr_comissao: order?.orders?.vlr_comissao,
              paymentTerms: order?.orders?.payment_terms,
              actionsPending: order?.orders?.actions_pending?.map((item: any) => ({
                sku: item.sku,
                product: item.product,
                price: item.price,
                unitPrice: item.unit_price,
                unitPricePerItem: item.unit_price_per_item,
                stPrice: item.st_price,
                integrationPrice: item.integration_price,
                quantity: item.quantity,
                packaging: item.packaging,
                status: item?.status,
                reason: item?.reason,
                ...products[item.sku],
              }))
            }]
          });
          clientsList?.push(order?.company?.id);
        } else if (clientsMap?.has(order?.company?.id)) {
          const value = clientsMap.get(order?.company?.id);
          const updatedValue = [
            ...value?.orders,
            {
              orderNumber: order?.orders?.order_number,
              erpNumber: order?.orders?.erp_order_number,
              orderNumberMagento: order?.orders?.order_number_magento,
              quantity: order?.orders?.items,
              status: order?.orders?.status,
              reason: order?.orders?.reason,
              orderDate: order?.orders?.order_date,
              total: order?.orders?.total,
              vlr_comissao: order?.orders?.vlr_comissao,
              paymentTerms: order?.orders?.payment_terms,
              actionsPending: order?.orders?.actions_pending?.map((item: any) => ({
                sku: item?.sku,
                product: item?.product,
                price: item?.price,
                unitPrice: item?.unit_price,
                unitPricePerItem: item?.unit_price_per_item,
                stPrice: item?.st_price,
                integrationPrice: item?.integration_price,
                quantity: item?.quantity,
                packaging: item?.packaging,
                status: item?.status,
                reason: item?.reason,
                ...products[Number(item?.sku)],
              }))
            }
          ];
          clientsMap.set(order?.company?.id, {
            ...value,
            orders: updatedValue,
          });
        }

        return {
          clientName: order?.company?.name,
          clientRefNumber: order?.company?.id,
          clientCnpj: order?.company?.cnpj,
          orders: {
            orderNumber: order?.orders?.order_number,
            erpNumber: order?.orders?.erp_order_number,
            orderNumberMagento: order?.orders.order_number_magento,
            quantity: order?.orders?.items,
            origin: order?.orders?.origin,
            status: order?.orders?.status,
            reason: order?.orders?.reason,
            orderDate: order?.orders?.order_date,
            total: order?.orders?.total,
            paymentTerms: order?.orders?.payment_terms,
            actionsPending: order?.orders?.actions_pending?.map((item: any) => ({

              sku: item?.sku,
              product: item?.product,
              price: item?.price,
              unitPrice: item?.unit_price,
              unitPricePerItem: item?.unit_price_per_item,
              stPrice: item?.st_price,
              stPricePackaging: item?.st_price_packaging,
              integrationPrice: item?.integration_price,
              integrationPriceFlex: item?.integration_price_flex,
              quantity: item?.quantity,
              quantityMultiplo: item?.quantity_multiplo,
              percentualFlex: item?.percentual_flex,
              packaging: item?.packaging,
              status: item?.status,
              reason: item?.reason,
              ...products[Number(item?.sku)],
            }))
          }
        }
      });
      if (synchedOrders?.length && orders?.length) {
        orders?.forEach((item: any) => {
          const findIndex = synchedOrders?.findIndex((order: any) => order?.company?.id === item?.clientRefNumber && order?.orders?.order_number === item?.orders?.orderNumber);
          if (findIndex > -1) {
            synchedOrders?.splice(findIndex, 1);
            setOnStorage("synched_orders", synchedOrders);
            synchedList?.splice(findIndex, 1);
          }
        })
      }
      setSynchedOrdersList(synchedList);
      setOrders(orders);

    }
  }, [data, products]);

  const ordersDate = useMemo(() => {
    return orders?.map((order: any) => {
      const dateString = order?.orders?.orderDate?.replace(/^[^,]*,\s*/, '');
      return new Date(`${dateString.split('/').reverse().join('-')}T00:00:00`);
    });
  }, [orders]);

  const filteredOrders = useMemo(() => {
    if (!filterDate) return orders;
    return orders.filter((order) => {
      const orderDate = new Date(`${order?.orders?.orderDate?.replace(/^[^,]*,\s*/, '').split('/').reverse().join('-')}T00:00:00`);
      return isSameDay(orderDate, filterDate);
    });
  }, [orders, filterDate]);

  return {
    ordersList: filteredOrders,
    ordersDate,
    offlineOrdersList: connectivityStatus === 'offline' ? offlineOrders : [],
    synchedOrdersList,
    loading,
    error,
    filterDate,
    setFilterDate
  };
}
