import * as S from './styles';
import { ClientProps } from '@/types';
import { RiMapPin2Line } from "react-icons/ri";

interface HeaderProps {
  clientName: string;
  client: ClientProps;
  clientRefNumber: string;
}

const Header = ({ clientRefNumber, clientName, client }: HeaderProps) => {
  return (
    <S.HeaderContainer>
      <S.HeadingContainer $borderBottom>
        <S.Title>
          <S.Tag>{clientRefNumber}</S.Tag>
          {clientName}
        </S.Title>
      </S.HeadingContainer>

      <S.HeadingContainer>
        <S.Title>
          <S.MapContent>
            <RiMapPin2Line fontSize={18} />
          </S.MapContent>
          {`${client.ENDERECO}`}, {`${client.NUMERO}`} - {`${client.BAIRRO}`}, {`${client.CIDADE}`} - {`${client.ESTADO}`}, {`${client.CEP}`}
        </S.Title>
      </S.HeadingContainer>
    </S.HeaderContainer>
  )
}

export default Header;
