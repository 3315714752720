import { getOnStorage, setOnStorage } from '@/shared'
import * as R from "@/constants/systemRoutes";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCart, useClient, useUserContext } from "@/hooks";
import { Menu, Header, CartCheckoutBlock, CheckoutProgressBar } from "@/components";

interface Props {
  children: any;
}

const doc = document as any;
const workerUrl =
  "data:application/javascript;base64," +
  btoa(`
  self.addEventListener('message', (e) => {
    if(e.data==='hello'){
      self.postMessage('hello');
    }
    debugger;
    self.postMessage('');
  });
`);

const enableValidationInspect = false;/* ![
  "localhost",
  "d1864eisj85olf.cloudfront.net",
  "pwa-rca-dev.grupopereira.link",
  "192.168.15.27",
  "192.168.1.13",
].includes(window.location.hostname); */

const { userAgent } = navigator;
const tabletRegex = /iPad|tablet|playbook|silk|Macintosh|Windows|Linux|X11/i;
const mobileRegex = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i;
const isMobile = mobileRegex.test(userAgent) && !tabletRegex.test(userAgent);

const checkIfDebuggerEnabled = async (): Promise<boolean> => {
  return new Promise((resolve) => {
    let fulfilled = false;
    const worker = new Worker(workerUrl);
    worker.onmessage = (e) => {
      const data = e.data;
      if (data === "hello") {
        setTimeout(() => {
          if (!fulfilled) {
            resolve(true);
            worker.terminate();
          }
        }, 100);
      } else {
        fulfilled = true;
        resolve(false);
        worker.terminate();
      }
    };
    worker.postMessage("hello");
  });
};

const Container = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isStarted = useRef(false);
  const { signOut } = useUserContext();
  const { selectedClient } = useClient();
  const { totalQuantity, isSearching, cartLoading } = useCart();

  useEffect(() => {
    if (!location.pathname.includes('login')) setOnStorage('last_route', location.pathname);

    const root = document.getElementById("root");
    if (root) root.classList.add("enter-efect");

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 200);

    setTimeout(() => {
      if (root) root.classList.remove("enter-efect");
    }, 2000);
  }, [location]);

  useEffect(() => {
    const isFirstLoad = getOnStorage('is_first_load')
    if (isFirstLoad) return
    
    navigator.serviceWorker?.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister().then(() => {
          document.cookie = 'Clear-Site-Data: "cache", "cookies", "executionContexts"';
          setOnStorage('is_first_load', 'true')
          window.location.reload();
        }).catch(error => {
          console.error('Service worker unregistration failed:', error);
        });
      });
    }).catch(error => {
      console.error('Service worker registration retrieval failed:', error);
    });
  }, [])

  useEffect(() => {
    const isEnabledRoutes = [
      R.LOGIN.link,
      R.COMPANY.link,
      R.DASHBOARD.link,
      R.MY_CLIENTS.link,
      R.SALES_CYCLE.link,
      R.USER_DATA.link,
      R.ORDER_STATUS_PAGE.link,
      R.PRODUCTIVITY_EXTRACT.link,
      R.INDICATORS.link,
      R.SALES_REPORT.link,
      R.NEW_CLIENT.link,
      R.PERFORMANCE_INDICATORS.link
    ].includes(location?.pathname);

    setTimeout(() => {
      if (selectedClient?.COD_CLIENTE || isEnabledRoutes) return;

      navigate(R.DASHBOARD.link);
    }, 4000);
  }, [location, navigate, selectedClient?.COD_CLIENTE]);

  const validate = useCallback(() => {
    if (
      !isMobile &&
      location.pathname !== R.LOGIN.link &&
      enableValidationInspect
    ) {
      const logout = () => {
        if (location.pathname === R.LOGIN.link) return;

        signOut();
        navigate(R.LOGIN.link);
      };

      setInterval(async () => {
        const result = await checkIfDebuggerEnabled();
        if (result) logout();
      }, 4000);

      const bloquearCliqueDireito = () => {
        if (doc.addEventListener) {
          doc.addEventListener("contextmenu", (e: any) => {
            e.preventDefault();
            return false;
          });
        } else {
          doc.attachEvent("oncontextmenu", (e: any) => {
            e = e || window.event;
            e.returnValue = false;
            return false;
          });
        }
      };

      const bloquearBotoesInspect = () => {
        doc.addEventListener("keydown", (e: any) => {
          const key = e.keyCode;
          const isAlt = !!e.altKey;
          const isCtrl = !!e.ctrlKey;
          const isMeta = !!e.metaKey;
          const isShift = !!e.shiftKey;
          const invalidKey = key === 73 || key === 74 || key === 67;

          const isInspect =
            key === 123 ||
            (isCtrl && key === 85) ||
            (isCtrl && isShift && key === 75) ||
            (isMeta && isAlt && invalidKey) ||
            (isMeta && isAlt && invalidKey) ||
            (isMeta && isShift && invalidKey) ||
            (isCtrl && isShift && invalidKey) ||
            (isMeta && key === 85);

          if (isInspect) e.preventDefault();
        });
      };

      bloquearBotoesInspect();
      bloquearCliqueDireito();
    }
  }, [location.pathname, navigate, signOut]);

  useEffect(() => {
    if (isStarted.current) return;
    isStarted.current = true;

    validate();
  }, [validate]);

  return (
    <>
      <Header
        routeLabelSize={location.pathname === R.ORDER_STATUS_PAGE.link ? 16 : 0}
        rightIcon={null}
        stickChild={
          location.pathname === R.CART.link &&
          !isSearching && <CheckoutProgressBar />
        }
      />
      {children}
      {location.pathname === R.CART.link && totalQuantity > 0 && !cartLoading && (
        <CartCheckoutBlock />
      )}
      <Menu />
    </>
  );
};

export default Container;
