import * as S from "./styles";
import { useEffect, useState } from "react";

interface ToolTipProps {
  text: string;
  time?: number;
  children: any;
  show?: boolean;
  bgcolor?: string;
  callback?: () => void;
  direction?: "top" | "bottom";
  displayMode?: "hover" | "click" | "default";
}

const ToolTip: React.FC<ToolTipProps> = ({
  text,
  show,
  time,
  bgcolor,
  callback,
  children,
  direction = "top",
  displayMode = "hover",
}) => {
  const [showTooltip, setShow] = useState(false);

  useEffect(() => {
    if (displayMode !== "click") return;

    setTimeout(() => {
      setShow(false);
    }, time ?? 3000);
  }, [showTooltip, time, displayMode, setShow]);

  return (
    <>
      {displayMode === "hover" ? (
        <S.Content
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {children}

          <S.Tolltip $bgcolor={bgcolor} direction={direction} $show={showTooltip}>
            {text}
          </S.Tolltip>
        </S.Content>
      ) : displayMode === "click" ? (
        <S.Content
          onClick={() =>
            !show && displayMode === "click" && setShow(!showTooltip)
          }
        >
          {children}

          <S.Tolltip
            $bgcolor={bgcolor}
            direction={direction}
            $show={showTooltip || !!show}
          >
            {text}
          </S.Tolltip>
        </S.Content>
      ) : callback ? (
        <S.Content onClick={() => callback()}>
          {children}

          <S.Tolltip $bgcolor={bgcolor} direction={direction} $show={!!show}>
            {text}
          </S.Tolltip>
        </S.Content>
      ) : (
        <S.Content>
          {children}

          <S.Tolltip $bgcolor={bgcolor} direction={direction} $show={!!show}>
            {text}
          </S.Tolltip>
        </S.Content>
      )}
    </>
  );
};

export default ToolTip;
